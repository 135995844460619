import React from 'react'
import "./style/card.css"
import "./style/responsive.css"

function Card() {
    return (
        <div className="cards">
            <div className="card cardCon">
                <div className='line'></div>
                <div className="card-1">
                    <h2 className="card__title">50M+</h2>
                    <p className="card__apply">
                        Downlods
                    </p>
                </div>
            </div>
            <div className="card cardCon">
                <div className='line'></div>
                <div className="card-1">
                    <h2 className="card__title">4.5</h2>
                    <p className="card__apply">
                        Start Rating
                    </p>
                </div>
            </div>
            <div className="card cardCon">
                <div className='line'></div>
                <div className="card-1">
                    <h2 className="card__title">32M</h2>
                    <p className="card__apply">
                        Active Users
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Card
import React from 'react'
import mobile from "../assets/image/mobile_mockup.png"
import badge from "../assets/image/playstore_badge.png"
import "./style/header.css"
import "./style/responsive.css"

function Header() {
    return (
        <div className='Container'>
            <div className="text">
                <div className="heading"><h1>LOOKING FOR THE BEST <span id='photo'>PHOTO</span> <span id='edting'> EDITING  </span> APP</h1></div>
                <div className="subHeading"><p>We come with a concept that makes it easy for
                    you to edit photo instant, many Templates and
                    communities will help you more</p></div>
                <div className="downloadBtn"><a href="https://play.google.com/store/apps/details?id=com.photoeditor.snapcial" target="_blank">
                    <img src={badge} alt="download badge" />
                </a>
                </div>
            </div>
            <div className="image">
                <img src={mobile} alt="mobile_mockup" className='header_mobile' />
            </div>
        </div>
    )
}

export default Header
import React from 'react'
import review from "../assets/image/Review.png"
import { ImStarFull } from "react-icons/im";
import bedge from "../assets/image/playstore_badge.png"
import "./style/review.css"
import "./style/responsive.css"

function Review() {
    return (
        <div className='reviewContainer'>
            <div className="reviewImg">
                <img src={review} alt="" className="reviewMobile" />
            </div>
            <div className="reviewTxt">
                <div className="reviewCard">
                    <h1 >4.5</h1>
                    <ImStarFull className="reviewStar" color="#EEB010" />
                </div>
                <div className="reviewHeading">
                    <h1>190K RATING <span className='reviewWord'>REVIEWS</span></h1>
                </div>
                <div className='reviewSubheading'>
                    <p>We are deeply focused on the quality and optimization of the diverse categories that we compete in. Every rating and review counts, giving us great motivation to keep improving.</p>
                </div>
                <div className="reviewDownBtn">
                    <a href="https://play.google.com/store/apps/details?id=com.photoeditor.snapcial" target="_blank">
                        <img src={bedge} alt="" className="bedge" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Review
import React from 'react'
import "./style/template.css"
// import data from './data';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import spiral from "../assets/image/Slide/Spiral.png"
import Creative from "../assets/image/Slide/Creative.png"
import Drip from "../assets/image/Slide/Drip.png"
import Fire from "../assets/image/Slide/Fire.png"
import Fireflies from "../assets/image/Slide/Fireflies.png"
import Laser_Light from "../assets/image/Slide/Laser_Light.png"
import Monsoon from "../assets/image/Slide/Monsoon.png"
import Neon from "../assets/image/Slide/Neon.png"
import Ocean_Thumb from "../assets/image/Slide/Ocean_Thumb.png"
import PaperTorn from "../assets/image/Slide/PaperTorn.png"
import Rainbow from "../assets/image/Slide/Rainbow.png"
import Shape from "../assets/image/Slide/Shape.png"
import Snakes from "../assets/image/Slide/Snakes.png"
import Split_Up from "../assets/image/Slide/Split_Up.png"
import Unique from "../assets/image/Slide/Unique.png"
import Wing from "../assets/image/Slide/Wing.png"




// const data = [
//     {
//         id: 1,
//         category: "spiral",
//         image: '../assets/image/spiral.png',
//     },
//     {
//         id: 2,
//         category: "glass",
//         image: "../assets/image/spiral.png",
//     },
//     {
//         id: 3,
//         category: "neon",
//         image: "../assets/image/spiral.png",
//     },
//     {
//         id: 4,
//         category: "collage",
//         image: "../assets/image/spiral.png",
//     },
//     {
//         id: 5,
//         category: "background",
//         image: "../assets/image/spiral.png",
//     },
// ]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                slidesToScroll: 1
            }
        }
    ]
};

function Template2() {






    return (
        <div className='templateContainer'>
            <div className="templateText">
                <div className="templateHeading"><h1>MOST POPULAR APP <span id='text'>TEMPLATE</span></h1></div>
                <div className="templateSubHeading"><p>Find the most useful & popular templates, Enjoy with milions of users from all over the world, Dont't miss it.</p></div>
            </div>
            {/* <div className="items">
                <button className='btn1' name="spiral" >Spiral</button>
                <button className='btns' name="collage" >Collage</button>
                <button className='btns' name="background" >Background</button>
                <button className='btns' name="neon" >Neon</button>
                <button className='btns' name="glass" >Glass</button>
            </div>
            <div className="underline"></div> */}
            {/* <div className="templateImgs"> */}

            {/* <div > */}
            <Slider  {...settings}>
                <div className='slide'>
                    <img src={spiral} alt="" />
                </div>
                <div className='slide'>
                    <img src={Creative} alt="" />

                </div>
                <div className='slide'>
                    <img src={Drip} alt="" />

                </div>
                <div className='slide'>
                    <img src={Fire} alt="" />

                </div>
                <div className='slide'>
                    <img src={Fireflies} alt="" />

                </div>
                <div className='slide'>
                    <img src={Laser_Light} alt="" />

                </div>
                <div className='slide'>
                    <img src={Monsoon} alt="" />

                </div>
                <div className='slide'>
                    <img src={Fireflies} alt="" />

                </div>
                <div className='slide'>
                    <img src={Neon} alt="" />

                </div>
                <div className='slide'>
                    <img src={Ocean_Thumb} alt="" />

                </div>
                <div className='slide'>
                    <img src={PaperTorn} alt="" />

                </div>
                <div className='slide'>
                    <img src={Rainbow} alt="" />

                </div>
                <div className='slide'>
                    <img src={Shape} alt="" />

                </div>
                <div className='slide'>
                    <img src={Snakes} alt="" />

                </div>
                <div className='slide'>
                    <img src={Split_Up} alt="" />

                </div>
                <div className='slide'>
                    <img src={Unique} alt="" />

                </div>
                <div className='slide'>
                    <img src={Wing} alt="" />

                </div>
            </Slider>
            {/* </div> */}

            {/* </div> */}

        </div>
    )
}


export default Template2
import React from 'react'
import logo from '../assets/image/logo.png'
import "./style/navbar.css"
import { RiVipCrownFill } from "react-icons/ri";
function Navbar() {
    return (
        <div className='mainContainer'>
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="navItems">
                <li><a href='#'>Discover</a></li>
                <li><a href='#'>Features</a></li>
                <li><a href='#'>Premium</a></li>
            </div>
            <div className="btn">
                <button><RiVipCrownFill className='crown' /><span style={{ marginLeft: 5, }}>PRO</span></button>
            </div>
        </div>
    )
}

export default Navbar
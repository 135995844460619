
import './App.css';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import Card from './Components/Card';
import Template from './Components/Template2';
import { FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import Review from './Components/Review';
import Fream from "./assets/image/Frame.png"
import Footer from './Components/Footer';
import "./Components/style/responsive.css"


function App() {

  const currentYear = new Date().getFullYear();


  return (

    <div className="App">

      <div className="blur-image">
        <Navbar />
        <Header />
      </div>
      <Card />
      <Template />
      {/* <img src={Slider} alt="Slider" width={1900} style={{ marginTop: 150 }} /> */}
      <Review />
      <div className="world">
        <div className="worldHeading">
          <h1>WE INTERACT WITH USERS FROM ALL OVER THE <span id="worldWord">WORLD...</span></h1>
        </div>
        <div className="worldSubheading">
          <p>From steady Markets like the US or EU to rapidly growing ones such as Asia or LATAM.</p>
        </div>
        <div className="worldCount"><h1>30,000,000+</h1>
          <p>Monthly Active Users</p></div>
        <div className="worldImg">
          <img src={Fream} alt="" />
        </div>
      </div>
      <Footer />
      {/*   */}
      <div className="footBar">
        <div className="footItems">
          <div className="snapCial">
            <h1>Snapcial</h1>
          </div>
          <div className="footList">
            <li>Discover</li>
            <li>Features</li>
            <li>Premium</li>
          </div>
          <div className="footIcons">
            <FaFacebookSquare className='footIcon' />
            <FaTwitterSquare className='footIcon' />
          </div>
        </div>
        <div className="footCopy">
          <p>Copyright © {currentYear} Snapcial App</p>
        </div>
      </div>
    </div>


  );
}

export default App;

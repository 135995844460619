import React from 'react'
import glass from "../assets/image/footerGlas.png"
import icons from "../assets/image/iconsGroup.png"
import badge from "../assets/image/playstore_badge.png"
import "./style/footer.css"
import "./style/responsive.css"

function Footer() {
    return (
        <div className='footerContainer'>
            <div className="footerImg">
                <img src={icons} alt="" className='footerIcons' />
                <img src={glass} alt="" className='footerGlass' />
            </div>
            <div className="footerText">
                <h1>EASY AND FUN PHOTO <span id='editingWord'>EDITING</span></h1>
                <p>We come with a concept that makes it easy for you to edit photo instant, many Templates and communities will help you more</p>
                <a href='https://play.google.com/store/apps/details?id=com.photoeditor.snapcial'>
                    <img src={badge} alt="" />
                </a>
            </div>
            {/* <div className="footerBtn">
                <img src={badge} alt="" />
            </div> */}
        </div>
    )
}

export default Footer